<template>
  <div>
    <div v-if="!inviteCode && !authenticated">
      <div style="text-align:center; font-size: 25px; padding: 40px 40px;">Um sich für eine Onlineprüfung zur Fachkraft für Rauchwarnmelder nach DIN 14676 anzumelden, klicken Sie bitte auf den Link in Ihrer Einladungsmail oder geben Sie hier einen Einladungscode ein.</div>
      <div style="text-align:center; padding: 20px 0px; display: flex; justify-content: center;"><input type="text" v-model="invite_code" style="height: 20px; margin-right: 10px; font-size:14px;" /><button @click="authenticate()" style="font-size: 16px;">Anmelden</button></div>
    </div>
    <div v-else>
      <div v-if="authenticated && attendance">
        <div class="exam-auth_attandance">Herzlich Wilkommen 
          <div class="exam-auth_attandance--name">{{attendance.first_name}}  {{attendance.last_name}}</div>
        </div>
        <div v-if="attendance.template.exam_type==='trainer'" class="exam-auth_attandance--head">Trainer-Onlineprüfung zur Fachkraft
          für Rauchwarnmelder nach
          DIN 14676</div>
        <div v-else class="exam-auth_attandance--head">Onlineprüfung zur Fachkraft
          für Rauchwarnmelder nach
          DIN 14676</div>
        <div class="exam-auth_attandance--desc">
           <div class="exam-auth_attandance--desc__head">Systemanforderungen</div>
           <div class="exam-auth_attandance--desc__headdesc">
             <p><strong>Internet Browser</strong><br>
            Die neuesten Versionen von Microsoft Edge, Safari, Chrome, und Firefox. Bitte löschen Sie Ihren Browser Cache vor dem Start der Prüfung.</p>           
            <p><strong>Webcam</strong><br>
            Die Prüfung wird unter persönlicher Aufsicht eines lizensierten Q-Trainers durchgeführt. Daher wird Ihr Q-Trainer die Prüfung per Onlinevideoverbindung verfolgen, dazu ist eine Webcam erforderlich.</p>
             </div>
            <div class="exam-auth_attandance--desc__head">Voraussetzungen </div>
            <div class="exam-auth_attandance--desc__headdesc">
              <p>Voraussetzung für die Prüfung ist eine vorhergehende Schulung durch einen Q-Trainer gemäß Ausbildungs- und Prüfungsordnung vom 03.03.2021 des Forum Brandrauchprävention. Teilnehmer müssen über ein „Technisches Grundverständnis“ (EQR-Niveau 3) verfügen. Diese Schulung soll Sie in die Lage versetzen, schutzzielorientiert die erforderliche Ausstattung von Gebäuden und Räumen mit Rauchwarnmeldern beurteilen und umsetzen zu können. </p>
              <p>Jeder Teilnehmer muss gegenüber dem lizensierten Q-Trainer, der die Prüfung leitet, seine Identität zweifelsfrei belegen.</p>
              <p>Nach erfolgreicher Teilnahme an dieser Prüfung erhalten Sie von Ihrem Q-Trainer ein Q-Fachkrafts-Zertifikat, welches 5 Jahre ab dem Tag der Prüfung gültig ist. Eine Aktualisierung ist alle 5 Jahre vorzunehmen. </p>

            </div>
            <div class="exam-auth_attandance--desc__head">Ausschluss</div>
            <div class="exam-auth_attandance--desc__headdesc">
              <p>Ihr Q-Trainer kann einen Teilnehmer mit sofortiger Wirkung von der weiteren Teilnahme an der Prüfung ausschließen, wenn der betreffende Teilnehmer eine Täuschungshandlung begeht oder den Ablauf der Prüfung erheblich stört. Für den ausgeschlossenen Teilnehmer gilt die Prüfung als nicht bestanden. Jeder Missbrauch der Prüfungssoftware, insbesondere das unerlaubte Bekanntmachen von Prüfungsfragen daraus, hat den Entzug der Q-Fachkrafts-Lizenz und den Ausschluss von künftigen Prüfungen zur Folge. </p>
              
            </div>
            <div class="exam-auth_attandance--desc__head">Wichtige Hinweise</div>
            <div class="exam-auth_attandance--desc__headdesc">
              <p><strong>Bitte achten Sie darauf, besonders bei kleineren Bildschirmen, immer bis zum Ende zu scrollen, um alle möglichen Antworten zu sehen!</strong></p>
             
              
              <p><strong>Bei den Multiple-Choice-Fragen sind von Ihnen alle zutreffenden Antwortoptionen anzuklicken.</strong> Bei jeder Frage ist mindestens eine Antwortoption zutreffend; es können auch mehrere oder alle Antwortoptionen zutreffend sein. Eine Frage gilt dann als falsch beantwortet, wenn nicht alle richtigen Antwortoptionen angeklickt wurden oder eine falsche Antwortoption angeklickt wurde. </p>
             
            </div>
            <div class="exam-auth_attandance--desc__head">Ende der Prüfung / Zeitrahmen</div>
            <div class="exam-auth_attandance--desc__headdesc">
              <p>Laut Prüfungsordnung stehen Ihnen für die reine Prüfung 60 Minuten zur Verfügung, sobald Sie „Prüfung starten“ klicken. Zum Einloggen, für den erläuternden Vorspann sowie als Puffer bei technischen Problemen zu Beginn stehen Ihnen zu Beginn rund 30 Minuten zur Verfügung. Stellt der Q-Trainer zusätzliche mündliche Prüfungsfragen, ist dafür zusätzlich Zeit zu gewähren.</p>
              <p>Die Prüfung wird nach Ablauf der Prüfungszeit automatisch beendet, auch wenn Sie nicht alle Fragen beantwortet haben. Für nicht beantwortete Fragen erhalten Sie keine Punkte. Sind Sie vor Ablauf der Zeit fertig, prüfen Sie noch einmal, ob Sie alle Fragen beantwortet und keine übersehen haben. Sie können dazu den VOR/ZURÜCK-Button nutzen oder die Durchnummerierung der Fragen oben von 1-30 nutzen. Wenn Sie die Prüfung einmal beendet haben, haben Sie keinen Zugriff mehr. </p>
              
              
            </div>

            <div class="exam-auth_attandance--desc__head">Auswertung der Prüfung </div>
            <div class="exam-auth_attandance--desc__headdesc">
              <p>Je nach Schwierigkeit und Relevanz werden die Fragen mit 2, 3 oder 4 Punkten bewertet. Die Prüfung beinhaltet insgesamt 30 Fragen mit zusammen 100 Punkten. Zum Bestehen der Prüfung muss ein Teilnehmer mindestens 65 Punkte erreichen. Eine Frage gilt dann als falsch beantwortet, wenn im Sinne der Fragestellung eine richtige Antwortoption nicht angeklickt oder eine falsche Antwortoption angeklickt wurde.</p>
              <p>Nach der Prüfung erhalten Sie Ihr Ergebnis innerhalb dieser Prüfungssoftware angezeigt sowie parallel per E-Mail an Ihre hinterlegte E-Mail-Adresse. Das Zertifikat erstellt Ihnen Ihr Q-Trainer. </p>
              </div>          
          
          </div>
           <div class="exam-auth_attandance--desc">
             <div class="exam-auth_attandance--desc__head exam-auth_attandance--desc__headnb">Einverständnis zur Datenspeicherung und zu den Prüfungsbedingungen (notwendig)</div>
             <div class="exam-auth_attandance--pp">
          
          <input type="checkbox" id="agreed_to_terms" v-model="agreed_to_terms" /> <label>Ich bin damit einverstanden, dass meine Prüfungsunterlagen und Daten (Prüfungsdatum, Name, Vorname sowie Firmen- oder private Adresse, E-Mail-Adresse und Q-Fachkraft ID) nach erfolgreicher Prüfung für die Dauer der Gültigkeit des Zertifikates von der eobiont GmbH im Auftrag des Forum Brandrauchprävention e.V. registriert und gespeichert werden.*
Ich bin mit den eingangs beschriebenen Prüfungsbedingungen gemäß Prüfungsordnung einverstanden.
</label>
         
        </div>
        <error v-if="agreed_terms_error && !agreed_to_terms" class="error">Sie müssen der Datenschutzerklärung zustimmen, bevor sie die Prüfung starten können!</error>
            </div>
          
        <!-- <div class="exam-auth_attandance--desc">
             <div class="exam-auth_attandance--desc__head exam-auth_attandance--desc__headnb">Einverständnis zur regelmäßigen fachlichen Information (optional)</div>
             <div class="exam-auth_attandance--nl">
          <input type="checkbox" id="subscribed_to_news" v-model="subscribed_to_news" /> <label>Bitte informieren Sie mich regelmäßig über Änderungen und Neuigkeiten im Zusammenhang mit der „Fachkraft für Rauchwarnmelder“. Ich kann diese Zustimmung jederzeit widerrufen. </label>
          
        </div>
            </div> -->
        
        
        
        <div class="exam_submit">
          <button @click="startExam" class="exam_submit--btn">Prüfung starten</button>
        </div>
      </div>
    </div>

    <error v-if="serverError" class="error" :err="serverError" />
  </div>
</template>

<script>

import Error from './Error.vue';
export default {
  components: { Error },
  props: {inviteCode: String},
  data: function () {
    return {
      invite_code: this.inviteCode,
      device_name: process.env.VUE_APP_DEVICE_NAME,
      attendance: null,
      subscribed_to_news: false,
      agreed_to_terms: false,
      agreed_terms_error: false,
      serverError: null
    }
  },
  computed: {
    authenticated() {
      return this.$store.state.authenticated;
    }
  },
  created: function () {
    if(this.invite_code) {
      this.authenticate();
    }
  },
  methods: {
    authenticate() {
      this.serverError = null;
      this.axios.post('/auth/token', {
        invite_code: this.invite_code,
        device_name: this.device_name
      }).then(response => {
        localStorage.setItem('authtoken', response.data.data.token)
        this.$store.commit('setAuthenticated', true);
        this.attendance = response.data.data.attendance;
        this.$store.commit('setAttendance', this.attendance);
      }).catch(error => {
        this.serverError = error;
      });
    },

    startExam() {
      this.agreed_terms_error = !this.agreed_to_terms;
      if(this.agreed_terms_error) return;

      this.serverError = null;
      const self = this;
      this.axios.post('/attendances/'+this.attendance.id+'/preferences',
          {'subscribed_to_news': this.subscribed_to_news, 'agreed_to_terms': this.agreed_to_terms})
          .then(response => {
        self.$store.commit('setAttendance', response.data.data);
        self.$router.push({ name: 'exam' });
      }).catch(error => {
        this.serverError = error;
      });
    }
  }
}
</script>



