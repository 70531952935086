<template>
  <div>
    <div class="exam-auth_attandance">
      <div v-if="template.exam_type==='trainer'">Trainer-Onlineprüfung:</div>
      <div v-else>Onlineprüfung:</div>
        <div>{{attendance.first_name}}  {{attendance.last_name}}</div>
      </div>
     <div class="exam-auth_attandance--head exam_result--head-yeah" v-if="attendance.passed">
      <div><h1>Herzlichen Glückwunsch!</h1>
      Sie haben die Onlineprüfung zur Fachkraft für
      Rauchwarnmelder nach DIN 14676 bestanden!</div>
    </div>
    <div class="exam-auth_attandance--head exam_result--head-ohno" v-else>
      <div><h1>Es tut uns Leid</h1>
      Leider haben Sie die Onlineprüfung
      zur Fachkraft für Rauchwarnmelder
      nach DIN 14676 nicht bestanden!</div>
    </div>
     <div class="exam-auth_attandance--desc">
      <p>Sie haben {{attendance.score}} von {{attendance.maxScore}} möglichen Punkten erreicht.<br>
        (Erläuterung: Die höchste Punktzahl ist {{attendance.maxScore}}, mindestens {{attendance.passScore}} Punkte werden benötigt.)
      </p>
      <p v-if="attendance.questionsWithWrongAnswers">Folgende Fragen haben Ihnen Schwierigkeiten
bereitet. Bitte schauen Sie sich diese nochmals
genauer an:</p>
    </div>
    
    <div class="exam-auth_attandance--desc_abstract-wrap">
      
      <div class="exam-auth_attandance--desc_abstract" v-for="(wrong, index) in attendance.questionsWithWrongAnswers" :key="index">
        <h2>{{wrong.question}}</h2>
        <p><strong>Begründung:</strong><br>
       {{wrong.explanation}}</p>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data: function () {
    return {

    }
  },
  computed: {
    attendance () {
      return this.$store.state.attendance;
    },
    template () {
      return this.$store.state.template;
    },
  },
  mounted: function () {
    this.result();
  },
  methods: {
    result() {
      this.axios.get('/attendances/'+this.attendance.id+'/result')
          .then(response => {
            this.$store.commit('setAttendance', response.data.data);
          })
          .catch(err => {
            this.serverError = err;
            });
    },
  }
}
</script>




