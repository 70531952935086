<template>
    <div class="exam_questions">      
      <fieldset>
        <legend align="center">Frage {{questionNumber}}</legend>
      <div class="exam_question">{{question.question}}</div>
      <img v-if="question.image_url" :src="question.image_url" />
      <div v-for="questionAnswer in question.answers" :key="questionAnswer.id" class="exam_answers">
        <input type="checkbox" :value="questionAnswer.id" @change="saveAnswer($event)" :checked="inAnswers(questionAnswer.id)"> <label>{{questionAnswer.answer}}</label>
      </div>
      </fieldset>
      <error v-if="serverError" class="error" :err="serverError" />
    </div>

</template>

<script>

import Error from './Error.vue';

export default {
  components: { Error },
  props: {questionNumber: [String,Number]},
  data: function () {
    return {
      serverError: null,
      answersLoaded: false,
      answers: []
    }
  },
  computed: {
    attendance () {
      return this.$store.state.attendance;
    },
    template () {
      return this.$store.state.template;
    },
    countQuestions () {
      return this.template.questions.length;
    },
    question () {
      return this.template.questions[this.questionNumber-1];
    },
    nextQuestionNumber() {
      return this.questionNumber < this.countQuestions ? this.questionNumber+1 : false;
    },
    previousQuestionNumber() {
      return this.questionNumber > 1 ? this.questionNumber-1 : false;
    }
  },
  mounted: function () {
    this.loadAnswers();
    this.$store.commit('addWasViewed', this.question.id);
    this.$store.commit('setCurrentQuestionNumber', this.questionNumber);
  },
  watch: {
    '$route.params.questionNumber': function () {
      this.loadAnswers();
      this.$store.commit('addWasViewed', this.question.id);
      this.$store.commit('setCurrentQuestionNumber', this.questionNumber);
    }
  },
  methods: {
    loadAnswers() {
      this.axios.get('/attendances/'+this.attendance.id+'/answers?question_id='+this.question.id)
          .then(response => {
            this.answers = response.data.data;
            this.answersLoaded = true;
          })
          .catch(err => {
            this.serverError = err;
            });
    },
    inAnswers(questionAnswerId) {
      return this.answers.find(x => x.question_answer_id === questionAnswerId) !== undefined;
    },
    saveAnswer(event) {
      let questionAnswerId = parseInt(event.target.value);
      this.serverError = null;
      if(event.target.checked) {
        this.axios.post(
            '/attendances/'+this.attendance.id+'/answers',
            {'question_id': this.question.id, 'question_answer_id': questionAnswerId}
            )
          .then((response) => {
            this.answers.push(response.data.data);
            this.$store.commit('addHasAnswer', this.question.id);
          })
            .catch(err => {
              this.serverError = err;
              event.target.checked = false;
              });
      }
      else {
        let answer = this.answers.find(x => x.question_answer_id === questionAnswerId);
        this.axios.delete(
            '/attendances/'+this.attendance.id+'/answers/'+answer.id
        )
            .then(() => {
              // remove answer from this.answers
              this.answers = this.answers.filter( answer => {
                if(answer.question_answer_id !== questionAnswerId) return answer;
              });
              if(this.answers.length === 0) {
                this.$store.commit('removeHasAnswer', this.question.id);
              }
            })
            .catch(err => {
              this.serverError = err;
              event.target.checked = true;
              });
      }
    }
  }
}
</script>

<style scoped>



</style>



