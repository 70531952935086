<template>

  <div>
    <div v-if="template.loaded">
      <timer v-if="started" v-on:minutes-left="onMinutesLeft" />
      <div class="exam-auth_attandance">
        <div v-if="template.exam_type==='trainer'">Trainer-Onlineprüfung:</div>
        <div v-else>Onlineprüfung:</div>
        <div>{{attendance.first_name}}  {{attendance.last_name}}</div>
      </div>
      <div class="exam_question_number--wrap">
        <div class="exam_question--head">Fragen</div>
        <ul class="exam_question_number">
          <li v-for="(question, index)  in template.questions" :key="index" :class="{ answered: questionHasAnswer(question.id) }">
            <router-link :to="{ name: 'examQuestion', params: { questionNumber: index+1 } }">{{index+1}}</router-link>
          </li>
        </ul>
        <div class="exam_question_number_answered-unanswered"><div>{{countAnsweredQuestions}} beantwortet</div>  <div>{{countUnansweredQuestions}} unbeantwortet</div></div>
      </div>
      <div v-if="lessThanFiveMinutesLeft" class="error exam_question--timewarning">Sie haben noch {{displayMinutesLeft}} min. Zeit,
        um Ihre Prüfung abzuschließen.</div>


      <error v-if="serverError" class="error" :err="serverError" />
      <router-view></router-view>

      <div class="exam_btn_nav" v-bind:class="{ exam_btn_nav_sb: hasPrevious }">
        <button v-if="hasPrevious" class="exam_btn"><router-link :to="{ name: 'examQuestion', params: { questionNumber: selectedQuestionIndex } }"><strong>&lt;</strong> zu Frage {{selectedQuestionIndex}}</router-link></button>
        <button v-if="allQuestionsViewed" class="exam_btn exam_btn--straight"><router-link :to="{name: 'submit'}">Prüfung sofort abgeben</router-link></button>
        <button v-if="hasNext" class="exam_btn"><router-link :to="{ name: 'examQuestion', params: { questionNumber: selectedQuestionIndex+2 } }">zu Frage {{selectedQuestionIndex+2}} <strong>&gt;</strong></router-link></button>
      </div>
    </div>

  </div>
</template>

<script>

import Error from './Error.vue';
import Timer from './Timer.vue';

export default {
  components: { Error, Timer },
  data: function () {
    return {
      serverError: null,
      minutesLeft: null
    }
  },
  computed: {
    attendance () {
      return this.$store.state.attendance;
    },
    selectedQuestionIndex() {
      return this.$store.state.currentQuestionNumber - 1;
    },
    started () {
      return !!this.$store.state.attendance.start_time;
    },
    template () {
      return this.$store.state.template;
    },
    countQuestions() {
      return this.template.questions.length;
    },
    countViewedQuestions() {
      return this.$store.state.questionsViewed.length;
    },
    allQuestionsViewed() {
      return this.countViewedQuestions === this.countQuestions
          || this.countQuestions === this.countAnsweredQuestions;
    },
    hasNext() {
      return this.selectedQuestionIndex < this.countQuestions-1;
    },
    hasPrevious() {
      return this.selectedQuestionIndex > 0;
    },
    answeredQuestions() {
      return this.$store.state.questionsAnswered;
    },
    countAnsweredQuestions() {
      return this.$store.state.questionsAnswered.length;
    },
    countUnansweredQuestions() {
      return this.countQuestions -  this.countAnsweredQuestions;
    },
    displayMinutesLeft() {
      return (this.minutesLeft > 0 ? this.minutesLeft: 0);
    },
    lessThanFiveMinutesLeft() {
      return (this.minutesLeft === parseInt(this.minutesLeft, 10))
      && this.minutesLeft <=5;
    },
    extraTimeAllowance () {
      return this.$store.state.template.extra_time;
    }
  },
  created: function () {
    this.loadTemplate();

    if(!this.started) {
      this.start();
    }
  },
  methods: {
    loadTemplate() {
      let self = this;
      this.axios.get('/templates/'+this.attendance.template_id)
          .then(response => {
            this.$store.commit('setTemplate', response.data.data);
            self.loadAnswers();
          })
          .catch(err => {
            this.serverError = err;
            });
    },

    start() {
      this.axios.post('/attendances/' + this.attendance.id + '/start', )
          .then(response => {
            this.$store.commit('setAttendance', response.data.data);
            this.$store.commit('setCurrentQuestionNumber', 1);
          })
          .catch(err => {
            this.serverError = err;
            });
    },

    loadAnswers() {
      const self = this;
      this.axios.get('/attendances/'+this.attendance.id+'/answers')
          .then((response) => {
            self.$store.commit('initializeHasAnswers');
            response.data.data.forEach(function(answer) {
              self.$store.commit('addHasAnswer', answer.question_id);
            });
          })
          .catch(err => {
            this.serverError = err;
            });
    },

    questionHasAnswer(id) {
      return this.answeredQuestions.includes(id);
    },

    onMinutesLeft (value) {
      if(value === -this.extraTimeAllowance) {
        this.submit();
      }
      else {
        this.minutesLeft = value;
      }
    },

    submit() {
      this.serverError = null;

      this.axios.post('/attendances/'+this.attendance.id+'/submit')
          .then(response => {
            this.$store.commit('setAttendance', response.data.data);
            this.$router.push({ name: 'result' });
          })
          .catch(err => {
            this.serverError = err;
            });
    }

  }
}
</script>

<style scoped>
li.answered a {
        color: #6A9699;   
  }

.router-link-exact-active {
        border-bottom: 2px solid #fff;
      }


.answered { font-weight: normal;}

</style>



